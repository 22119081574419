
/**
 *@name: 分片视频播放组件
 *@author: lili
 *@date: 2024-01-15 11:25
 *@description：分片视频播放组件
 *@update:  2024-01-15 11:25
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import DPlayer from "dplayer";
import Hls from "hls.js";

@Component({})
export default class VideoDplayer extends Vue {

  @Prop(String) id!: string;

  @Prop(String) value!: string;

  player:any;

  url: string = location.origin + '/api' + config.videoDownloadUrl + this.value;
  @Watch("value", {immediate: true})
  onValueChange(newVal: string) {
    if (newVal) {
     this.$nextTick(()=>{
       this.initPlayer()
     })
    }
  }
  /**
   * 销毁视频
   */
   destory  ()  {
    if (this.player) {
      this.player.pause()
      this.player.destroy()
      this.player = null
    }
  }
  initPlayer() {
    this.destory();
    if ((!this.id && !this.value )|| (this.value&&this.value.split('.')[1] == 'mp4'))
      return
    this.player = new DPlayer({
      container: document.getElementById(this.id),
      autoplay: false,
      lang: 'zh-cn',
      live: false,
      loop: false,
      mutex: false,
      video: {
        url: location.origin + '/api' + config.videoDownloadUrl + this.value, // 地址
        // pic: picUrl,  // 封面
        // type: 'customHls',
        type: 'customHls',
        customType: {
          customHls: function (video: any, player: any) {
            const hls = new Hls();
            hls.loadSource(video.src);
            hls.attachMedia(video);
          },
        },
      },
    });
    // 禁用视频右键
    // @ts-ignore
    document.getElementById(this.id).oncontextmenu = () => {
      // @ts-ignore
      document.querySelector(".dplayer-menu").style.display = "none";
      // @ts-ignore
      document.querySelector(".dplayer-mask").style.display = "none";
      return false;
    };
  }

}
