
/**
 * @name: specs
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-视频管理-添加，修改  规格
 * @update: 2023-06-01 11:21
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {courseVideoSkuVoList, ICourse} from "@/apis/coursesManage/courses/types";
// @ts-ignore
import Bus from '@/mui/components/SimpleUpload/js/bus'
import videoDplayer from "@/mui/components/VideoDplayer";
import {deepCopy} from "@/utils/common";
import {checkPrice} from "@/constants/validators";

@Component({components: {videoDplayer}})
export default class ProductSpecs extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: ICourse;
  // 新增修改表单rules
  @Prop(Object) rules!: any;

  //列表数据
  tableData: any[] = []
  loading = false;
  specsData:courseVideoSkuVoList= {
    skuName: '',
    sort:'',
    video: '',
    index: 0
  };

  specsRules:any = {
    skuName: [
      {required: true, message: '输入分P名称', trigger: 'change'}
    ],
    sort: [
      {required: true, message: '请选择排序', trigger: 'change'}
    ],

  }
  // 编辑弹窗
  dialogVisible: boolean = false;

  selectVideoUpload:any;

  @Watch('modelForm')
  onChangeValue(newVal: any, oldVal: any) {
    if (newVal.courseVideoSkuVoList) {
      this.tableData=[];
      this.tableData= newVal.courseVideoSkuVoList.map((item: any,i:number) => {
        item.isUploadLoading = false;
        item.index=i;
        return item;
         // this.tableData.push(item)
      });
      console.log(this.tableData ,'this.tableData -----')
    }
  }

  /**
   * 添加规格
   *
   */
  handleAddSpecsName() {
    if (!this.specsData.skuName || this.specsData.skuName == '') {
      this.$message.error("请输入分P名称")
      return
    }
    if (!this.specsData.sort) {
      this.$message.error("请输入排序")
      return
    }
    let index = this.tableData.findIndex(e => e.sort == this.specsData.sort);
    if (index != -1) {
      this.$message.error("排序已存在！")
      return
    }
    this.loading = true;
    let obj = {
      skuName: this.specsData.skuName,
      sort: this.specsData.sort,
      video: '',
      index: this.tableData.length,
      isUploadLoading:false
    }
    this.tableData.push(obj);
    // this.tableData = this.tableData.sort((a, b) => a.sort - b.sort);
    // this.modelForm.courseVideoSkuDtoList = this.tableData;
    this.setSkuDtoList();
    this.loading = false
    this.specsData = {
      skuName: '',
      sort: '',
      video: '',
      index: 0
    }
  }


  /**
   * 修改
   * @param index
   */
  handleEdit(index: number) {
    this.dialogVisible = true;
    // let obj=Object.assign({},this.tableData[index])
    let obj=deepCopy(this.tableData[index]);
    this.specsData =obj;
    this.$set(this,'specsData',obj)
  }

  /**
   * 删除
   * @param index
   */
  handleDel(index: number) {
    this.tableData.splice(index, 1);
    this.setSkuDtoList();
    // this.modelForm.courseVideoSkuDtoList = this.tableData;
  }

  /**
   * 修改规格
   */
  enterDialog() {
    if (!this.specsData.skuName || this.specsData.skuName == '') {
      this.$message.error("请输入分P名称")
      return
    }
    if (!this.specsData.sort ) {
      this.$message.error("请输入排序")
      return
    }
    let index = this.tableData.findIndex(e => e.sort == this.specsData.sort);
    if (index != -1&&index!=this.specsData.index) {
      this.$message.error("排序已存在！")
      return
    }
    this.tableData[this.specsData.index||0] = this.specsData;
    this.setSkuDtoList();
    // this.tableData = this.tableData.sort((a, b) => a.sort - b.sort);
    // this.modelForm.courseVideoSkuDtoList = this.tableData;
    this.dialogVisible = false;
    this.specsData = {
      skuName: '',
      sort: '',
      video: '',
      index: 0
    }
  }

  /**
   * 排序并设置DTO
   */
  setSkuDtoList(){
   // let arr= deepCopy(this.tableData)
    this.tableData = this.tableData.map((item:any, index:number) => {item.index=index; return item} );
    this.tableData= this.tableData.sort((a:any, b:any) => a.sort - b.sort);
    this.modelForm.courseVideoSkuDtoList = this.tableData;
  }


  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm, tab)
  }

  /**
   * 上传视频
   * @param item
   */
  handleUpload(item:any,index:number) {
    this.selectVideoUpload=item;
    this.selectVideoUpload.index=index;
    // 打开文件选择框
    Bus.$emit('openSimpleUpload', {
      // 给服务端的额外参数
      params: {
        index:item.sort
      }
    })
  }
  /**
   * 删除视频
   * @param item
   */
  handleRemoveUpload(item:any,index:number){
    this.tableData[index].video ='';
    this.tableData[index].isUploadLoading = false;
  }

  mounted() {
    /**
     * 文件选择后的回调
     * @param e
     */
    Bus.$on('fileSimpleUploadAdded', (e:any) => {
      let index = e.file.params.index;
      let sortIndex= this.tableData.findIndex(e => (e.sort) == index);
      let obj=deepCopy(this.tableData)
      obj[sortIndex].isUploadLoading = true;
      this.$set(this,'tableData', obj);
    })

    /**
     * 文件上传成功的回调
     * @param e
     */
    Bus.$on('fileSimpleUploadSuccess', (e:any) => {
      let index = e.file.params.index;
      let sortIndex= this.tableData.findIndex(e => (e.sort) == index);
      let url =e.data.url;
      this.tableData[sortIndex].video =url;
      this.tableData[sortIndex].isUploadLoading = false;
      this.setSkuDtoList();
    })
    /**
     * 文件上传失败的回调
     * @param e
     */
    Bus.$on('fileSimpleUploadError', (e:any) => {
      let index = e.file.params.index;
      let sortIndex= this.tableData.findIndex(e => e.sort ==index);
      this.tableData[sortIndex].isUploadLoading = false;
    })
    /**
     * 文件上传删除的回调
     * @param e
     */
    Bus.$on('fileSimpleUploadRemoved', (e:any) => {
      if(!e.file||(e.file&&e.file.params.bol))
        return
      let index = e.file?.params.index;
      let sortIndex= this.tableData.findIndex(e => e.sort ==index);
      this.tableData[sortIndex].video = '';
      this.tableData[sortIndex].isUploadLoading = false;
    })

  }

  beforeDestroy() {
    Bus.$emit('closeSimpleUpload',{})
    Bus.$off('fileSimpleUploadAdded')
    Bus.$off('fileSimpleUploadSuccess')
    Bus.$off('fileSimpleUploadError')
    Bus.$off('fileSimpleUploadRemoved')
  }
  tableHeaderColor({ row, column, rowIndex, columnIndex}:any ) {
    if (columnIndex === 1) {
      return 'color:red;'; //红色
    }
  }
  created() {
  }
}
