/**
 * @name: 分片视频播放组件入口
 * @author: lili
 * @date: 2024-01-15 11:25
 * @description：index
 * @update:  2024-01-15 11:25
 */
import VideoDplayer from "./VideoDplayer.vue"
import Vue from "vue";

// @ts-ignore
VideoDplayer.install = (vue: Vue) => {
  Vue.component(VideoDplayer.name, VideoDplayer);
}

export default VideoDplayer;
